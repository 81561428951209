<template>
	<CommonPage
		title="Privacy policy"
		:back="true">
		<div class="privacy">
			<h2>Information We Collect</h2>
		<p>We collect non-personally identifiable information to improve your experience on <a href="/">quranicwisdom.online</a>. This includes data such as your IP address, browser type, and pages visited.</p>

		<h2>Use of Information</h2>
		<p>The information we collect helps us understand how users interact with our website, allowing us to enhance our content and services.</p>

		<h2>Cookies</h2>
		<p>We use cookies to improve your browsing experience. Cookies are small files stored on your device that help us recognize you and remember your preferences.</p>

		<h2>Data Security</h2>
		<p>We implement reasonable security measures to protect your information from unauthorized access or disclosure.</p>

		<h2>Changes to Privacy Policy</h2>
		<p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of the new policy.</p>

		<h2>Contact Us</h2>
		<p>
			If you have any questions or concerns about our User Terms or Privacy Policy, please contact us at
			<a href="mailto:quranicwisdom.support@gmail.com">quranicwisdom.support@gmail.com</a>
		</p>
		</div>

	</CommonPage>
</template>

<script>
const title = 'Quranic&Wisdom Online - Privacy Policy'
const description = 'Learn about our privacy practices at Quranic&Wisdom Online. Understand how we collect, use, and protect your personal information.'

export default {
	name: 'privacy',
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
	mounted () {
		document.querySelector('body').style.backgroundColor = '#fcfbfb'
	}
}
</script>
